export const homeDataOne = {
  id: "about",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Smart Parking Garage IoT Product",
  headline: "Creating a More Effective Parking Experience",
  description:
    "Our product is designed to create an efficient parking experience for drivers by providing real-time information on parking availability and streamlining the parking process. With the device, drivers will be able to quickly find available parking spots and navigate to them with ease.",
  imgStart: false,
  img: require("src/assets/img/garage-1.jpg"),
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeDataTwo = {
  id: "pricing",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Affordable Pricing",
  headline: "Cost-Effective Solutions for Parking Optimization",
  description:
    "Our Smart Parking Garage IoT device is designed to be low-cost and easy to install, allowing you to upgrade your parking garage without costly structural changes.",
  buttonLabel: "Get Started",
  imgStart: true,
  img: require("src/assets/img/garage-2.jpg"),
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeDataThree = {
  id: "services",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Elevate with Custom IoT Solutions",
  headline: "Customized Solutions to Optimize Parking",
  description:
    "With our smart parking garage IoT device, you can provide real-time information on parking availability, streamline the parking process, and enhance the overall parking experience for your customers.",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("src/assets/img/city-driver.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};
