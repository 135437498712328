import styled from "styled-components";

export const CardContainer = styled.div`
  background: #0d0d0d;
  margin: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 830px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const Card = styled.div`
  background-color: var(--clr-dark-blue);
  padding: 60px 80px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px var(--clr-accent-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin-inline: auto;

  @media (max-width: 480px) {
    padding: 20px 10px;
  }
`;

export const BarCard = styled.div`
  background-color: var(--clr-dark-blue);
  height: 393px;
  margin-inline: auto;
  padding: 10px 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px var(--clr-accent-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 830px) {
    margin-top: 30px;
  }

  @media (max-width: 600px) {
    height: 250px;
    padding: 20px 20px;
  }

  @media (max-width: 500px) {
    height: 200px;
    padding: 10px 10px;
  }

  @media (max-width: 420px) {
    height: 150px;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -khtml-border-radius: 50%;
`;

export const ProgressBar = styled.div`
  display: inline-block;
  position: relative;
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -khtml-border-radius: 50%;
  color: #fff;
  box-shadow: 0 0 0 2px var(--clr-accent-blue);

  &::after,
  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: "";
    width: 110px;
    height: 110px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -khtml-border-radius: 50%;
    box-shadow: inherit;
  }
`;

export const Circle = styled.svg.attrs({
  xmlns: "http://www.w3.org/2000/svg",
})`
  stroke-dasharray: 410;
  stroke-dashoffset: ${(props) => ((100 - props.percent) / 100) * 410};
  transform: rotate(-90deg);
  transition: stroke-dashoffset 0.5s linear;
`;

export const Number = styled.span`
  font-size: 21px;
  font-weight: bold;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const Label = styled.label`
  font-weight: bold;
  margin-right: 10px;
  color: #fff;
`;

export const Input = styled.p`
  padding: 5px 10px;
  border-radius: 5px;
  border: 1;
  color: #fff;
  font-weight: 500;
  font-size: 21px;

  @media (max-width: 480px) {
    padding: 2px 7px;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100px;
  height: 100px;
`;

export const Loader = styled.div`
  width: 100%;
  height: 100%;
  border: 5px solid #162534;
  border-top-color: #2cd9ff;
  border-bottom-color: #18a0fb;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
`;

export const InnerLoader = styled.div`
  width: 100%;
  height: 100%;
  border: 5px solid #162534;
  border-top-color: #2cd9ff;
  border-bottom-color: #18a0fb;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
`;
